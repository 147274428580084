import moment from "moment";

export const stringToDateFormatter = (date: string): string => {
  return moment(date).format("YYYY-MM-DD");
};

export const stringToDateTimerFormatter = (date?: string): string => {
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
};

export const formatVersionString = (text: string): string => {
  return text.trim();
};
